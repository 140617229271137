import React, { useState, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth'; // Ensure correct import
import awsconfig from './aws-exports';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

Amplify.configure(awsconfig);

function Dashboard() {
  const [batches, setBatches] = useState([]);
  const [message, setMessage] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'batchId', direction: 'ascending' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    try {
      const session = await fetchAuthSession();
      console.log('Session:', session); // Added for debugging

      const idToken = session.tokens.idToken.toString();
      console.log('ID Token:', idToken); // Added for debugging

      const response = await fetch('https://vgpqjs03lc.execute-api.us-east-1.amazonaws.com/dev/batchlist', {
        headers: {
          Authorization: `Bearer ${idToken}`, // **Modified Line**
        },
      });

      console.log('Response Status:', response.status); // Added for debugging

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }

      const data = await response.json();
      console.log('Fetched Data:', data); // Added for debugging
      setBatches(data);
    } catch (error) {
      console.error('Error fetching batches:', error);
      setMessage('Error fetching batches');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    sortBatches(key, direction);
  };

  const sortBatches = (key, direction) => {
    const sortedBatches = [...batches].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setBatches(sortedBatches);
  };

  const handleBatchClick = (batchId) => {
    navigate(`/results/${batchId}`);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <div className="dashboard">
      <div className="dashboard-heading">
        <h2>Riddle: Dashboard</h2>
      </div>
      {message && <p>{message}</p>}
      <div className="batches">
        <div className="batch-headers">
          <span onClick={() => handleSort('batchId')}>Batch ID {getSortIndicator('batchId')}</span>
          <span onClick={() => handleSort('fileName')}>File Name {getSortIndicator('fileName')}</span>
          <span onClick={() => handleSort('taskType')}>Task Type {getSortIndicator('taskType')}</span>
          <span onClick={() => handleSort('uploadDate')}>Uploaded At {getSortIndicator('uploadDate')}</span>
          <span onClick={() => handleSort('status')}>Status {getSortIndicator('status')}</span>
          <span onClick={() => handleSort('processedDate')}>Processed At {getSortIndicator('processedDate')}</span>
          <span>Results</span>
        </div>
        {batches.map(batch => (
          <div key={batch.batchId} className="batch">
            <span onClick={() => handleBatchClick(batch.batchId)}>{batch.batchId.slice(0, 8)}...</span>
            <span>{batch.fileName}</span>
            <span>{batch.taskType}</span>
            <span>{batch.uploadDate}</span>
            <span>{batch.status}</span>
            <span>{batch.processedDate || 'N/A'}</span>
            <span>
              {batch.status === 'PROCESSED' ? (
                <a href={`/results/${batch.batchId}`}>View Results</a>
              ) : (
                'N/A'
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default withAuthenticator(Dashboard);