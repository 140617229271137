import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoIdentityClient, GetIdCommand } from "@aws-sdk/client-cognito-identity";
import { v4 as uuidv4 } from 'uuid';
import awsconfig from './aws-exports';

// Function to create an S3 client
export const createS3Client = (idToken) => {
  const region = awsconfig.aws_project_region;
  const identityPoolId = awsconfig.aws_cognito_identity_pool_id;
  const userPoolId = awsconfig.aws_user_pools_id;

  return new S3Client({
    region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region },
      identityPoolId,
      logins: {
        [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
      },
    }),
  });
};

// Function to get the Identity Pool User ID
export const getIdentityPoolUserId = async (idToken) => {
  const cognitoIdentityClient = new CognitoIdentityClient({ region: awsconfig.aws_project_region });
  const logins = {
    [`cognito-idp.${awsconfig.aws_project_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`]: idToken,
  };

  const identityParams = {
    IdentityPoolId: awsconfig.aws_cognito_identity_pool_id,
    Logins: logins,
  };

  const identityData = await cognitoIdentityClient.send(new GetIdCommand(identityParams));
  const identityId = identityData.IdentityId;
  console.log("Identity Pool User ID:", identityId);
  return identityId;
};

// Removed the sanitizeIdentityPoolUserId function

export const calculateTotalChunks = (file, chunkSize) => {
  return Math.ceil(file.size / chunkSize);
};

// Function to chunk and upload the file
export const chunkAndUploadFile = async (
  file,
  chunkSize,
  batchId,
  idToken,
  updateStatus,
  taskType,
  taskMetadata,
  modelS3Location = null
) => {
  const identityPoolUserId = await getIdentityPoolUserId(idToken); // Get the full Identity Pool User ID
  // Removed sanitization to use the full Identity Pool User ID
  const s3Client = createS3Client(idToken); // Initialize the S3 client here

  const reader = new FileReader();

  reader.onload = async (event) => {
    const fileData = event.target.result;
    const totalChunks = calculateTotalChunks(file, chunkSize);
    let currentChunkIndex = 0;
    let start = 0;
    let header = '';
    let isHeaderProcessed = false;
    let partialLine = '';
    let rowCount = 0;

    while (start < fileData.byteLength) {
      const end = Math.min(start + chunkSize, fileData.byteLength);
      let chunk = fileData.slice(start, end);
      const chunkText = new TextDecoder().decode(chunk);

      partialLine += chunkText;
      const lines = partialLine.split('\n');
      partialLine = lines.pop(); // Save the last partial line for the next chunk

      if (!isHeaderProcessed) {
        header = lines.shift(); // Extract header
        isHeaderProcessed = true;
      }

      const chunkContent = lines.join('\n');
      rowCount += lines.length;

      const chunkId = uuidv4();
      const chunkKey = `chunks/${identityPoolUserId}/${batchId}/${chunkId}.csv`; // Use full Identity Pool User ID as the prefix
      const chunkBody = `${header}\n${chunkContent}`; // Exclude partialLine for the next chunk

      const putObjectParams = {
        Bucket: "my-data-chunks-bucket",
        Key: chunkKey,
        Body: chunkBody,
        ContentType: 'text/csv',
        Metadata: {
          'x-amz-meta-task-type': taskType,
          'x-amz-meta-task-metadata': taskMetadata,
          'x-amz-meta-row-count': rowCount.toString(), // Ensure this is a string
          'x-amz-meta-chunk-size': chunkSize.toString(), // Include chunk size as metadata
          ...(modelS3Location && { 'x-amz-meta-model-location': modelS3Location }) // Add model location metadata if available
        }
      };

      // Log the metadata being sent
      console.log(`Chunk ${currentChunkIndex + 1}/${totalChunks} metadata:`, putObjectParams.Metadata);

      try {
        await s3Client.send(new PutObjectCommand(putObjectParams));
        console.log(`Successfully uploaded chunk ${currentChunkIndex + 1}/${totalChunks} to key: ${chunkKey}`);
      } catch (error) {
        console.error(`Error uploading chunk ${currentChunkIndex + 1}/${totalChunks} to key: ${chunkKey}`, error);
        throw error;
      }

      // Update the upload status
      updateStatus(`${++currentChunkIndex}/${totalChunks} chunks uploaded`);

      start += chunkSize;
    }

    // Handle the remaining partial line
    if (partialLine) {
      const chunkId = uuidv4();
      const chunkKey = `chunks/${identityPoolUserId}/${batchId}/${chunkId}.csv`; // Use full Identity Pool User ID as the prefix
      const chunkBody = `${header}\n${partialLine}`;
      rowCount++; // Include the partial line in the row count

      const putObjectParams = {
        Bucket: "my-data-chunks-bucket",
        Key: chunkKey,
        Body: chunkBody,
        ContentType: 'text/csv',
        Metadata: {
          'x-amz-meta-task-type': taskType,
          'x-amz-meta-task-metadata': taskMetadata,
          'x-amz-meta-row-count': rowCount.toString(), // Ensure this is a string
          'x-amz-meta-chunk-size': chunkSize.toString(), // Include chunk size as metadata
          ...(modelS3Location && { 'x-amz-meta-model-location': modelS3Location }) // Add model location metadata if available
        }
      };

      // Log the metadata being sent
      console.log(`Final chunk metadata:`, putObjectParams.Metadata);

      await s3Client.send(new PutObjectCommand(putObjectParams));

      updateStatus(`${++currentChunkIndex}/${totalChunks} chunks uploaded`);
    }

    updateStatus(`Upload complete: ${totalChunks}/${totalChunks} chunks uploaded`);
  };

  reader.readAsArrayBuffer(file);
};
