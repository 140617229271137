import React, { useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { fetchAuthSession } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import './SubmitChunks.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { calculateTotalChunks, chunkAndUploadFile, createS3Client, getIdentityPoolUserId } from './chunkHelper'; // Removed sanitizeIdentityPoolUserId import
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"; 

Amplify.configure(awsconfig);

function SubmitChunks() {
  const [file, setFile] = useState(null);
  const [modelFile, setModelFile] = useState(null); // New state for model file
  const [message, setMessage] = useState('');
  const [batchId, setBatchId] = useState(null);
  const [taskType, setTaskType] = useState('linear-regression');
  const [taskMetadata, setTaskMetadata] = useState({});
  const [columns, setColumns] = useState([]);
  const [chunkSize, setChunkSize] = useState(5 * 1024 * 1024); // Default to 5MB
  const [totalChunks, setTotalChunks] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('0/0 chunks');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const firstLine = text.split('\n')[0];
        const cols = firstLine.split(',').map(col => col.trim());
        setColumns(cols);

        setTaskMetadata(prevMetadata => ({ ...prevMetadata, numColumns: cols.length }));
      };
      const blob = file.slice(0, 2048);
      reader.readAsText(blob);

      // Calculate total chunks
      const chunks = calculateTotalChunks(file, chunkSize);
      setTotalChunks(chunks);
    }
  };

  const handleModelFileChange = (event) => {
    setModelFile(event.target.files[0]); // Handle model file selection
  };

  const handleTaskTypeChange = (event) => {
    setTaskType(event.target.value);
    setTaskMetadata({});
  };

  const handleTaskMetadataChange = (field, value) => {
    setTaskMetadata(prevMetadata => ({ ...prevMetadata, [field]: value }));
  };

  const handleChunkSizeChange = (event) => {
    const size = parseInt(event.target.value, 10);
    setChunkSize(size);

    if (file) {
      const chunks = calculateTotalChunks(file, size);
      setTotalChunks(chunks);
    }
  };

  const createRiddleSourceEntry = async (batchId, fileName, taskType, taskMetadata, owner, modelS3Location = null) => {
    const path = '/start';

    const session = await fetchAuthSession(); // Use your method to fetch the session
    const idToken = session.tokens.idToken.toString(); // Ensure this fetches the correct ID token

    const init = {
      body: JSON.stringify({
        batchId,
        fileName,
        taskType,
        taskMetadata,
        owner,
        modelS3Location, // Include model S3 location in the RiddleSource entry
      }),
      headers: {
        Authorization: `Bearer ${idToken}`, // Ensure Authorization header has Bearer prefix
        'Content-Type': 'application/json'
      },
    };

    const response = await fetch(`https://0pawfsvt1a.execute-api.us-east-1.amazonaws.com/dev${path}`, {
      method: 'POST',
      headers: init.headers,
      body: init.body
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
    }

    return await response.json();
  };

  const handleFileUpload = async () => {
    if (!file || (taskType === 'tensorflow' && !modelFile)) { // Ensure both files are selected for TensorFlow
      setMessage('Please select both the data and model files to upload');
      return;
    }

    setIsUploading(true);

    try {
      const session = await fetchAuthSession(); // Fetch the session correctly
      const idToken = session.tokens.idToken.toString();
      const identityPoolUserId = await getIdentityPoolUserId(idToken); // Get the Identity Pool User ID using the same function as in chunkHelper.js
      const owner = identityPoolUserId; // Use full Identity Pool User ID
      const newBatchId = uuidv4();
      setBatchId(newBatchId);

      let modelS3Location = null;
      if (taskType === 'tensorflow' && modelFile) {
        // Upload the model file
        const s3Client = createS3Client(idToken);
        const modelKey = `chunks/${owner}/${newBatchId}/${modelFile.name}`; // Store model at the batch level within the "chunks" folder

        const putObjectParams = {
          Bucket: "my-data-chunks-bucket", // Use the same S3 bucket as for chunks
          Key: modelKey,
          Body: modelFile,
          ContentType: modelFile.type,
          Metadata: {
            'x-amz-meta-task-type': taskType,
            'x-amz-meta-batch-id': newBatchId,
            'x-amz-meta-owner': owner
          }
        };

        try {
          await s3Client.send(new PutObjectCommand(putObjectParams));
          modelS3Location = modelKey; // Store the S3 location of the model
          console.log(`Model uploaded to: ${modelS3Location}`);
        } catch (error) {
          console.error('Error uploading model file:', error);
          throw new Error(`Error uploading model file: ${error.message}`);
        }
      }

      const taskMetadataString = JSON.stringify({
        ...taskMetadata,
        numColumns: columns.length,
        ...(taskType === 'random-forest' && {
            n_estimators: 100,
            max_depth: null,
            min_samples_split: 2,
            maxFeatures: 0.5,
            bootstrap: true
        }),
        ...(taskType === 'tensorflow' && {
            modelType: 'neural-network',
            epochs: 10
        })
      });

      console.log('Task Type being sent:', taskType, 'Task Metadata being sent:', taskMetadataString, 'Batch ID:', newBatchId);

      // Create RiddleSource entry via API
      await createRiddleSourceEntry(newBatchId, file.name, taskType, taskMetadataString, owner, modelS3Location);

      // Show message to the user
      setMessage(`Upload initiated. Batch ID: ${newBatchId}`);
      setUploadStatus('0/0 chunks');

      // Chunk and upload the file
      await chunkAndUploadFile(file, chunkSize, newBatchId, idToken, (status) => setUploadStatus(status), taskType, taskMetadataString, modelS3Location);

      // Call the new API endpoint to update the RiddleSource status
      await fetch(`https://uz6i28d4ke.execute-api.us-east-1.amazonaws.com/dev/upload-done`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          batchId: newBatchId,
          owner,
          fileName: file.name
        })
      });

      setMessage(`File uploaded and record created: ${file.name}`);
    } catch (error) {
      console.error('Error uploading file:', error);
      const errorMessage = error.message || JSON.stringify(error);
      const errorResponse = error.response ? JSON.stringify(error.response) : 'No response';
      const errorRequest = error.request ? JSON.stringify(error.request) : 'No request';
      setMessage(`Error uploading file: ${errorMessage}\nResponse: ${errorResponse}\nRequest: ${errorRequest}`);
    } finally {
      setIsUploading(false);
    }
  };

  const renderTaskMetadataFields = () => {
    if (taskType === 'linear-regression' || taskType === 'babywasm') {
      return (
        <>
          <div className="form-group">
            <label htmlFor="target-variable">Target Variable</label>
            <select
              id="target-variable"
              value={taskMetadata.targetVariable || ''}
              onChange={(e) => handleTaskMetadataChange('targetVariable', e.target.value)}
              required
            >
              <option value="">Select Target Variable</option>
              {columns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </>
      );
    } else if (taskType === 'tensorflow') {
      return (
        <>
          <div className="form-group">
            <label htmlFor="model-type">Model Type</label>
            <select
              id="model-type"
              value={taskMetadata.modelType || 'neural-network'}
              onChange={(e) => handleTaskMetadataChange('modelType', e.target.value)}
              required
            >
              <option value="neural-network">Neural Network</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="epochs">Epochs</label>
            <input
              type="number"
              id="epochs"
              value={taskMetadata.epochs || 10}
              onChange={(e) => handleTaskMetadataChange('epochs', e.target.value)}
              required
            />
          </div>

          <div className="form-group centered">
            <label htmlFor="model-upload">Upload TensorFlow Model</label> {/* New upload field for model */}
            <input
              type="file"
              id="model-upload"
              accept=".keras,.h5,.json"
              onChange={handleModelFileChange}
              required
            />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className="submit-task-page">
      <div className="submit-task-heading">
        <h2>Riddle: Submit Chunks</h2>
      </div>
      <div className="submit-task-form">
        <div className="form-group centered">
          <label htmlFor="file-upload">Upload Dataset</label>
          <input
            type="file"
            id="file-upload"
            accept=".csv"
            onChange={handleFileChange}
            required
          />
        </div>

        <div className="sub-box">
          <div className="form-group">
            <label htmlFor="number-of-columns">Number of Columns: {columns.length}</label>
          </div>

          <div className="form-group">
            <label htmlFor="task-type">Task Type</label>
            <select id="task-type" value={taskType} onChange={handleTaskTypeChange} required>
              <option value="linear-regression">Linear Regression</option>
              <option value="random-forest">Random Forest</option>
              <option value="tensorflow">TensorFlow</option> {/* Task Type 2 for TensorFlow */}
              <option value="task-type-3">Task Type 3</option> {/* Blank Template for Task Type 3 */}
            </select>
          </div>

          {renderTaskMetadataFields()}

          <div className="form-group">
            <label htmlFor="chunk-size">Chunk Size</label>
            <select id="chunk-size" value={chunkSize} onChange={handleChunkSizeChange} required>
              <option value={5 * 1024 * 1024}>5MB</option>
              <option value={10 * 1024 * 1024}>10MB</option>
              <option value={20 * 1024 * 1024}>20MB</option>
              <option value={40 * 1024 * 1024}>40MB</option>
            </select>
          </div>
        </div>

        <div className="form-group centered">
          <button onClick={handleFileUpload} disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>

        {message && (
          <div className="message-box">
            <div>{message}</div>
            {batchId && (
              <>
                <br />
                <div>Batch ID: {batchId}</div>
                <br />
                <div>Upload status: {uploadStatus}</div>
                <br />
                <div className="center-link">
                  <Link to={`/results/${batchId}`}>Check Status</Link>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(SubmitChunks);
